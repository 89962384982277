export const SupportQR = (props) => {
  return (
    <div id='portfolio' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>QR code plaque</h2>
          <p>
           Choose your QR code plaque between a large choice
          </p>
        </div>
        <div className='row'>
          <div className='portfolio-items'>
            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='portfolio-item'>
                <div className='hover-bg'>
                  {' '}
                    <div className='hover-text'>
                      <h4>Free Stickers</h4>
                    </div>
                    <img
                      src='img/qrSupport/QR1.png'
                      className='img-responsive'
                      alt='Project Title'
                    />{' '}
                </div>
              </div>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='portfolio-item'>
                <div className='hover-bg'>
                  {' '}
                    <div className='hover-text'>
                      <h4>Metallic Support</h4>
                    </div>
                    <img
                      src='img/qrSupport/QR2.png'
                      className='img-responsive'
                      alt='Project Title'
                    />{' '}
                </div>
              </div>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='portfolio-item'>
                <div className='hover-bg'>
                  {' '}
                    <div className='hover-text'>
                      <h4>Wood QR</h4> 
                    </div>
                    <img
                      src='img/qrSupport/QR3.png'
                      className='img-responsive'
                      alt='Project Title'
                    />{' '}
                </div>
              </div>
            </div>
            
            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='portfolio-item'>
                <div className='hover-bg'>
                  {' '}
                    <div className='hover-text'>
                      <h4>Plaque designed</h4>
                    </div>
                    <img
                      src='img/qrSupport/QR4.png'
                      className='img-responsive'
                      alt='Project Title'
                    />{' '}
                </div>
              </div>
            </div>

            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='portfolio-item'>
                <div className='hover-bg'>
                  {' '}
                    <div className='hover-text'>
                      <h4>NFC QR Code</h4>
                    </div>
                    <img
                      src='img/qrSupport/QR5.png'
                      className='img-responsive'
                      alt='Project Title'
                    />{' '}
                </div>
              </div>
            </div>



            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='portfolio-item'>
                <div className='hover-bg'>
                  {' '}
                    <div className='hover-text'>
                      <h4>QR Metallic</h4>
                    </div>
                    <img
                      src='img/qrSupport/QR6.png'
                      className='img-responsive'
                      alt='Project Title'
                    />{' '}
                </div>
              </div>
            </div>
      
          </div>
        </div>
      
      </div>
    </div>
  )
}
