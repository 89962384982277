export const Gallery = (props) => {
  return (
    <div id='portfolio' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>TEMPLATES</h2>
          <p>
            Browse through our professionally designed selection of free templates and customize a design for any occasion.
          </p>
        </div>
        <div className='row'>
          <div className='portfolio-items'>
            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='portfolio-item'>
                <div className='hover-bg'>
                  {' '}
                  <a
                    href='./basic-menu'
                    data-lightbox-gallery='gallery1'
                  >
                    <div className='hover-text'>
                      <h4>POPULAR</h4>
                    </div>
                    <img
                      src='img/menu/basic-menu.png'
                      className='img-responsive'
                      alt='Project Title'
                    />{' '}
                  </a>{' '}
                </div>
              </div>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='portfolio-item'>
                <div className='hover-bg'>
                  {' '}
                  <a
                    href='./dominos-menu'
                    data-lightbox-gallery='gallery1'
                  >
                    <div className='hover-text'>
                      <h4>FRENCH</h4>
                    </div>
                    <img
                      src='img/menu/pizza-menu.png'
                      className='img-responsive'
                      alt='Project Title'
                    />{' '}
                  </a>{' '}
                </div>
              </div>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='portfolio-item'>
                <div className='hover-bg'>
                  {' '}
                  <a
                    href='./fish-menu'
                    data-lightbox-gallery='gallery1'
                  >
                    <div className='hover-text'>
                      <h4>ITALIAN</h4> 
                    </div>
                    <img
                      src='img/menu/menu-fish.png'
                      className='img-responsive'
                      alt='Project Title'
                    />{' '}
                  </a>{' '}
                </div>
              </div>
            </div>
            
            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='portfolio-item'>
                <div className='hover-bg'>
                  {' '}
                  <a
                    href='./mariage-announcement'
                    data-lightbox-gallery='gallery1'
                  >
                    <div className='hover-text'>
                      <h4>INVITATION</h4>
                    </div>
                    <img
                      src='img/menu/mariage-bg.png'
                      className='img-responsive'
                      alt='Project Title'
                    />{' '}
                  </a>{' '}
                </div>
              </div>
            </div>

            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='portfolio-item'>
                <div className='hover-bg'>
                  {' '}
                  <a
                    href='./resume'
                    data-lightbox-gallery='gallery1'
                  >
                    <div className='hover-text'>
                      <h4>CV</h4>
                    </div>
                    <img
                      src='img/menu/CV.png'
                      className='img-responsive'
                      alt='Project Title'
                    />{' '}
                  </a>{' '}
                </div>
              </div>
            </div>



            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='portfolio-item'>
                <div className='hover-bg'>
                  {' '}
                  <a
                    href='./template-card'
                    data-lightbox-gallery='gallery1'
                  >
                    <div className='hover-text'>
                      <h4>Id Card</h4>
                    </div>
                    <img
                      src='img/menu/bg-id.png'
                      className='img-responsive'
                      alt='Project Title'
                    />{' '}
                  </a>{' '}
                </div>
              </div>
            </div>
            
            
            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='portfolio-item'>
                <div className='hover-bg'>
                  {' '}
                  <a
                    href='https://templatereact-healthyqr.netlify.app/'
                    data-lightbox-gallery='gallery1'
                  >
                     <div className='hover-text'>
                      <h4>Menu for healthy food</h4>
                    </div> 
                    <iframe className='img-responsive' title={'title'} src={"https://templatereact-healthyqr.netlify.app/"} height={50} width={50}/>         
                   {' '}
                  </a>{' '}
                </div>
              </div>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='portfolio-item'>
                <div className='hover-bg'>
                  {' '}
                  <a
                    href='https://zingy-figolla-0e37c6.netlify.app/'
                    data-lightbox-gallery='gallery1'
                  >
                     <div className='hover-text'>
                      <h4>Menu Special cuisine</h4>
                    </div> 
                    <iframe className='img-responsive' title={'title'} src={"https://zingy-figolla-0e37c6.netlify.app/"} height={50} width={50}/>         
                   {' '}
                  </a>{' '}
                </div>
              </div>
            </div>

            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='portfolio-item'>
                <div className='hover-bg'>
                  {' '}
                  <a
                    href='https://templtae-react-tailwind-qr.netlify.app'
                    data-lightbox-gallery='gallery1'
                  >
                    <div className='hover-text'>
                      <h4>Black carbon</h4>
                    </div>
                    <iframe className='img-responsive' title={'title'} src={"https://templtae-react-tailwind-qr.netlify.app/"} height={50} width={50}/>         
                   {' '}
                  </a>{' '}
                </div>
              </div>
            </div>

            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='portfolio-item'>
                <div className='hover-bg'>
                  {' '}
                  <a
                    href='https://templatehtmldesignqr.netlify.app/'
                    data-lightbox-gallery='gallery1'
                  >
                    <div className='hover-text'>
                      <h4>Menu Meat BBQ</h4>
                    </div>
                    <iframe className='img-responsive' title={'title'} src={"https://templatehtmldesignqr.netlify.app/"} height={50} width={50}/>         
                   {' '}
                  </a>{' '}
                </div>
              </div>
            </div>

            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='portfolio-item'>
                <div className='hover-bg'>
                  {' '}
                  <a
                    href='https://templatehtmlqr.netlify.app/'
                    data-lightbox-gallery='gallery1'
                  >
                    <div className='hover-text'>
                      <h4>Menu Blue waves</h4>
                    </div>
                    <iframe className='img-responsive' title={'title'} src={"https://templatehtmlqr.netlify.app/"} height={50} width={50}/>         
                   {' '}
                  </a>{' '}
                </div>
              </div>
            </div>

            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='portfolio-item'>
                <div className='hover-bg'>
                  {' '}
                  <a
                    href='https://template-tailwind-qr.netlify.app/'
                    data-lightbox-gallery='gallery1'
                  >
                    <div className='hover-text'>
                      <h4>Menu Fastfood</h4>
                    </div>
                    <iframe className='img-responsive' title={'title'} src={"https://template-tailwind-qr.netlify.app/"} height={50} width={50}/>         
                   {' '}
                  </a>{' '}
                </div>
              </div>
            </div>


            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='portfolio-item'>
                <div className='hover-bg'>
                  {' '}
                  <a
                    href='https://templtaereactqr.netlify.app/'
                    data-lightbox-gallery='gallery1'
                  >
                    <div className='hover-text'>
                      <h4>Menu black original</h4>
                    </div> 
                    <iframe className='img-responsive' title={'title'} src={"https://templtaereactqr.netlify.app/"} height={50} width={50}/>         
                   {' '}
                  </a>{' '}
                </div>
              </div>
            </div>

          </div>
        </div>
      
      </div>
    </div>
  )
}
