
import './menu.scss';
import background from './img/sqala.jpeg';
import pizzaImage from './img/pizza.jpeg';
import tajineImage from './img/tajine1.jpg';

export const MenuBasic = (props) => {
    return (
        <div className='morocco-container'>
            <div className='sqala-header-background flex'>

                <div className=' sqala-logo	'></div>
                <select className='selectflag'>
                    <option>Francais</option>
                    <option>English</option>
                    <option>Spagnol</option>
                </select>

            </div>
            <div className={"all-height morocco-back-ground"} style={{ backgroundImage: `url(${background})` }}>
                <div className={"all-width"}>
                    <h1>La Sqala Casablanca</h1>
                    <p class="morocco-p">Restaurant Traditionnelle</p>
                </div>

                <div className='morocco-contact-us'>
                    <div className='morocco-container-p'>
                        <p class="morocco-p">Contactez-nous</p>
                        <p class="morocco-p">+212 (0) 5 324 432</p>
                    </div>
                </div>
            </div>
            <div class="back-black-opac section-bg-image parallax-window" data-bleed="0" data-natural-height="2500" data-natural-width="1920" data-parallax="scroll" data-image-src="../images/hd-portrait-3.jpg">
                <div class="container content paddinTop">
                    <div className="back-white">
                        <div className='moroccan-gate_flex1'>
                        </div>
                        <div className='moroccan-gate_flex1'>
                            <div className='moroccan-gate_header10'>
                            </div>
                        </div>

                        <div className='moroccan-gate_flex'>
                            <div className='moroccan-gate_header01'>
                            </div>
                        </div>

                        <div className='back-red'>

                            <div className='moroccan-gate_header'>
                                <div className='moroccan-gate_header_image'></div>

                                <div class="social-group">
                                </div>

                                <div class="social-group">
                                    <span>
                                        RESTAURANT - CAFÉ MAURE</span> </div>

                            </div>
                            <div className='moroccan-gate_header1 social-group'>
                                <div className='flex moroccan-gate_bg'>
                                    <a target="_blank" href="#" data-social="share-facebook"><i class="fa fa-facebook fa-3x"></i></a>
                                    <a target="_blank" href="#" data-social="share-twitter"><i class="fa fa-twitter fa-3x"></i></a>
                                    <a target="_blank" href="#" data-social="share-google"><i class="fa fa-instagram fa-3x"></i></a>
                                    <a target="_blank" href="#" data-social="share-linkedin"><i class="fa fa-youtube fa-3x"></i></a>

                                </div>

                            </div>
                            <div className='moroccan-gate_header2'></div>
                            <div className='moroccan-gate_header3'>

                            </div>
                            <div class="all-width-100 col-center morocco-container">
                                <div class="title-base marginTop20">
                                    <h2>Traditional Meals</h2>
                                    <p class="morocco-p">Slow cook meals and fresh fishes</p>
                                </div>
                                <div class=" ">
                                    <div class="col-md-6  paddin0">
                                        <div class="list-items">

                                            <div class="list-item list-item-img">
                                                <div class="row center-row-2">
                                                    <div class="col-md-3">
                                                        <div className={"meal"} style={{ backgroundImage: `url(${tajineImage})` }}></div>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <div>
                                                            <h3 class="morocco-h3">Tajine poulet</h3>
                                                            <p class="morocco-p"> Mixed lettuce, cherry tomatoes, onion, cucumber, olives and feta served with a Greek style dressing </p>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-1 pricing-container">
                                                        <span>8$</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="list-item list-item-img">
                                                <div class="row center-row-2">
                                                    <div class="col-md-3">
                                                        <div className={"meal"} style={{ backgroundImage: `url(${background})` }}></div>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <div>
                                                            <h3 class="morocco-h3">Chicken Caesar salad</h3>
                                                            <p class="morocco-p"> Lettuce, cherry tomatoes sliced chicken breast, crispy croûtons and a Caesar dressing </p>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-1 pricing-container">
                                                        <span>9$</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="list-item list-item-img">
                                                <div class="row center-row-2">
                                                    <div class="col-md-3">
                                                        <div className={"meal"} style={{ backgroundImage: `url(${background})` }}></div>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <div>
                                                            <h3 class="morocco-h3">Chicken Caesar salad</h3>
                                                            <p class="morocco-p">Lettuce, cherry tomatoes sliced chicken breast, crispy croûtons and a Caesar dressing </p>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-1 pricing-container">
                                                        <span>9$</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="list-item list-item-img">
                                                <div class="row center-row-2">
                                                    <div class="col-md-3">
                                                        <div className={"meal"} style={{ backgroundImage: `url(${background})` }}></div>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <div>
                                                            <h3 class="morocco-h3">Steamed king crab</h3>
                                                            <p class="morocco-p"> Duis aute irure dolo irure dolor in reprehenderit in voluptate velito esse cillume. </p>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-1 pricing-container">
                                                        <span>9$</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6  paddin0">
                                        <div class="list-items">

                                            <div class="list-item list-item-img">
                                                <div class="row center-row-2">
                                                    <div class="col-md-3">
                                                        <div className={"meal"} style={{ backgroundImage: `url(${background})` }}></div>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <div>
                                                            <h3 class="morocco-h3">Roasted Mediterranean</h3>
                                                            <p class="morocco-p">Lettuce, cherry tomatoes, courgettes and aubergines all roasted to perfection with a creamy Goats cheese dressing</p>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-1 pricing-container">
                                                        <span>9$</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="list-item list-item-img">
                                                <div class="row center-row-2">
                                                    <div class="col-md-3">
                                                        <div className={"meal"} style={{ backgroundImage: `url(${background})` }}></div>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <div>
                                                            <h3 class="morocco-h3">Steamed king crab</h3>
                                                            <p class="morocco-p"> Duis aute irure dolo irure dolor in reprehenderit in voluptate velito esse cillume. </p>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-1 pricing-container">
                                                        <span>9$</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="list-item list-item-img">
                                                <div class="row center-row-2">
                                                    <div class="col-md-3">
                                                        <div className={"meal"} style={{ backgroundImage: `url(${background})` }}></div>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <div>
                                                            <h3 class="morocco-h3">Steamed king crab</h3>
                                                            <p class="morocco-p"> Duis aute irure dolo irure dolor in reprehenderit in voluptate velito esse cillume. </p>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-1 pricing-container">
                                                        <span>9$</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="list-item list-item-img">
                                                <div class="row center-row-2">
                                                    <div class="col-md-3">
                                                        <div className={"meal"} style={{ backgroundImage: `url(${background})` }}></div>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <div>
                                                            <h3 class="morocco-h3">Steamed king crab</h3>
                                                            <p class="morocco-p"> Duis aute irure dolo irure dolor in reprehenderit in voluptate velito esse cillume. </p>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-1 pricing-container">
                                                        <span>9$</span>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class="title-base">
                                    <h2>Seasonals and burgers</h2>
                                    <p class="morocco-p">Genuine and fresh ingredients</p>
                                </div>
                                <div class=" ">
                                    <div class="col-md-6  paddin0">
                                        <div class="list-items">

                                            <div class="list-item list-item-img">
                                                <div class="row center-row-2">
                                                    <div class="col-md-3">
                                                        <div className={"meal"} style={{ backgroundImage: `url(${pizzaImage})` }}></div>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <div>
                                                            <h3 class="morocco-h3">Steamed king crab</h3>
                                                            <p class="morocco-p"> Duis aute irure dolo irure dolor in reprehenderit in voluptate velito esse cillume. </p>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-1 pricing-container">
                                                        <span>9$</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="list-item list-item-img">
                                                <div class="row center-row-2">
                                                    <div class="col-md-3">
                                                        <div className={"meal"} style={{ backgroundImage: `url(${background})` }}></div>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <div>
                                                            <h3 class="morocco-h3">Steamed king crab</h3>
                                                            <p class="morocco-p"> Duis aute irure dolo irure dolor in reprehenderit in voluptate velito esse cillume. </p>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-1 pricing-container">
                                                        <span>9$</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="list-item list-item-img">
                                                <div class="row center-row-2">
                                                    <div class="col-md-3">
                                                        <div className={"meal"} style={{ backgroundImage: `url(${background})` }}></div>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <div>
                                                            <h3 class="morocco-h3">Steamed king crab</h3>
                                                            <p class="morocco-p"> Duis aute irure dolo irure dolor in reprehenderit in voluptate velito esse cillume. </p>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-1 pricing-container">
                                                        <span>9$</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="list-item list-item-img">
                                                <div class="row center-row-2">
                                                    <div class="col-md-3">
                                                        <div className={"meal"} style={{ backgroundImage: `url(${background})` }}></div>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <div>
                                                            <h3 class="morocco-h3">Steamed king crab</h3>
                                                            <p class="morocco-p"> Duis aute irure dolo irure dolor in reprehenderit in voluptate velito esse cillume. </p>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-1 pricing-container">
                                                        <span>9$</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6  paddin0">
                                        <div class="list-items">

                                            <div class="list-item list-item-img">
                                                <div class="row center-row-2">
                                                    <div class="col-md-3">
                                                        <div className={"meal"} style={{ backgroundImage: `url(${background})` }}></div>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <div>
                                                            <h3 class="morocco-h3">Steamed king crab</h3>
                                                            <p class="morocco-p"> Duis aute irure dolo irure dolor in reprehenderit in voluptate velito esse cillume. </p>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-1 pricing-container">
                                                        <span>9$</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="list-item list-item-img">
                                                <div class="row center-row-2">
                                                    <div class="col-md-3">
                                                        <div className={"meal"} style={{ backgroundImage: `url(${background})` }}></div>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <div>
                                                            <h3 class="morocco-h3">Steamed king crab</h3>
                                                            <p class="morocco-p"> Duis aute irure dolo irure dolor in reprehenderit in voluptate velito esse cillume. </p>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-1 pricing-container">
                                                        <span>9$</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="list-item list-item-img">
                                                <div class="row center-row-2">
                                                    <div class="col-md-3">
                                                        <div className={"meal"} style={{ backgroundImage: `url(${background})` }}></div>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <div>
                                                            <h3 class="morocco-h3">Steamed king crab</h3>
                                                            <p class="morocco-p"> Duis aute irure dolo irure dolor in reprehenderit in voluptate velito esse cillume. </p>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-1 pricing-container">
                                                        <span>9$</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="list-item list-item-img">
                                                <div class="row center-row-2">
                                                    <div class="col-md-3">
                                                        <div className={"meal"} style={{ backgroundImage: `url(${background})` }}></div>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <div>
                                                            <h3 class="morocco-h3">Steamed king crab</h3>
                                                            <p class="morocco-p"> Duis aute irure dolo irure dolor in reprehenderit in voluptate velito esse cillume. </p>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-1 pricing-container">
                                                        <span>9$</span>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class="title-base">
                                    <h2>Desserts &amp; creams</h2>
                                    <p class="morocco-p">Hand made ice creams and desserts</p>
                                </div>
                                <div class=" ">
                                    <div class="col-md-6  paddin0">
                                        <div class="list-items">

                                            <div class="list-item list-item-img">
                                                <div class="row center-row-2">
                                                    <div class="col-md-3">
                                                        <div className={"meal"} style={{ backgroundImage: `url(${pizzaImage})` }}></div>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <div>
                                                            <h3 class="morocco-h3">Steamed king crab</h3>
                                                            <p class="morocco-p"> Duis aute irure dolo irure dolor in reprehenderit in voluptate velito esse cillume. </p>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-1 pricing-container">
                                                        <span>9$</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="list-item list-item-img">
                                                <div class="row center-row-2">
                                                    <div class="col-md-3">
                                                        <div className={"meal"} style={{ backgroundImage: `url(${background})` }}></div>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <div>
                                                            <h3 class="morocco-h3">Steamed king crab</h3>
                                                            <p class="morocco-p"> Duis aute irure dolo irure dolor in reprehenderit in voluptate velito esse cillume. </p>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-1 pricing-container">
                                                        <span>9$</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="list-item list-item-img">
                                                <div class="row center-row-2">
                                                    <div class="col-md-3">
                                                        <div className={"meal"} style={{ backgroundImage: `url(${background})` }}></div>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <div>
                                                            <h3 class="morocco-h3">Steamed king crab</h3>
                                                            <p class="morocco-p"> Duis aute irure dolo irure dolor in reprehenderit in voluptate velito esse cillume. </p>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-1 pricing-container">
                                                        <span>9$</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="list-item list-item-img">
                                                <div class="row center-row-2">
                                                    <div class="col-md-3">
                                                        <div className={"meal"} style={{ backgroundImage: `url(${background})` }}></div>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <div>
                                                            <h3 class="morocco-h3">Steamed king crab</h3>
                                                            <p class="morocco-p"> Duis aute irure dolo irure dolor in reprehenderit in voluptate velito esse cillume. </p>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-1 pricing-container">
                                                        <span>9$</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6  paddin0">
                                        <div class="list-items">

                                            <div class="list-item list-item-img">
                                                <div class="row center-row-2">
                                                    <div class="col-md-3">
                                                        <div className={"meal"} style={{ backgroundImage: `url(${background})` }}></div>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <div>
                                                            <h3 class="morocco-h3">Steamed king crab</h3>
                                                            <p class="morocco-p"> Duis aute irure dolo irure dolor in reprehenderit in voluptate velito esse cillume. </p>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-1 pricing-container">
                                                        <span>9$</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="list-item list-item-img">
                                                <div class="row center-row-2">
                                                    <div class="col-md-3">
                                                        <div className={"meal"} style={{ backgroundImage: `url(${background})` }}></div>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <div>
                                                            <h3 class="morocco-h3">Steamed king crab</h3>
                                                            <p class="morocco-p"> Duis aute irure dolo irure dolor in reprehenderit in voluptate velito esse cillume. </p>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-1 pricing-container">
                                                        <span>9$</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="list-item list-item-img">
                                                <div class="row center-row-2">
                                                    <div class="col-md-3">
                                                        <div className={"meal"} style={{ backgroundImage: `url(${background})` }}></div>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <div>
                                                            <h3 class="morocco-h3">Steamed king crab</h3>
                                                            <p class="morocco-p"> Duis aute irure dolo irure dolor in reprehenderit in voluptate velito esse cillume. </p>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-1 pricing-container">
                                                        <span>9$</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="list-item list-item-img">
                                                <div class="row center-row-2">
                                                    <div class="col-md-3">
                                                        <div className={"meal"} style={{ backgroundImage: `url(${background})` }}></div>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <div>
                                                            <h3 class="morocco-h3">Steamed king crab</h3>
                                                            <p class="morocco-p"> Duis aute irure dolo irure dolor in reprehenderit in voluptate velito esse cillume. </p>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-1 pricing-container">
                                                        <span>9$</span>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>

                </div>
            </div>

        </div>
    )
}